import { combineReducers, createStore, applyMiddleware, compose } from "redux";
import { enhanceReduxMiddleware } from "kepler.gl/middleware";
import thunk from "redux-thunk";
import keplerReducer from "./reducers/index";
import { loadUser, reducer as oidcReducer } from "redux-oidc";
import userManager from "./utils/userManager";
import { connectRouter } from 'connected-react-router'
import { routerMiddleware } from 'connected-react-router'



export default function configureStore(history, initialState) {
  const reducers = combineReducers({
    app: keplerReducer,
    oidc: oidcReducer,
    router: connectRouter(history)
  });

  const middlewares = enhanceReduxMiddleware([thunk, routerMiddleware(history)]);
  const enhancers = [applyMiddleware(...middlewares)];

  const composeEnhancers =
    window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose; // add support for Redux dev tools

  const store = createStore(reducers, initialState, composeEnhancers(...enhancers));

  loadUser(store, userManager);


  return store;
}
