
export const messages = {
  en: {
    'subscription.NotFound': '...something went wrong 😞 ',
    'subscription.NotFound.Description': 'We can\'t find a subscription for this account ({accountName}). Please contact us for the full version.',
    'subscription.Found': 'Subscriptions',
    'subscription.Found.Description': 'We found the following subscriptions: ',

    'settings.AccountInfo': 'Account info',
    'settings.Name': 'Name',
    'settings.Email': 'Email',
    'settings.Subscriptions': 'Subscriptions',
    'settings.KeplerInfo': 'Kepler info',
    'settings.KeplerVersion': 'Kepler version',
  }
};
