import React, { Component } from "react";
import { connect } from "react-redux";
import { CallbackComponent } from "redux-oidc";
import userManager from "../utils/userManager";
import PropTypes from "prop-types";
import { push } from "connected-react-router";
import { userFound } from "redux-oidc/src/actions"

class CallbackPage extends Component {
    render() {
        return (
            <CallbackComponent
                userManager={userManager}
                successCallback={(user) => {
                    this.props.dispatch(userFound(user));
                    this.props.dispatch(push("/"));
                }}
                errorCallback={error => {
                    this.props.dispatch(push("/Login"));
                    console.error(error);
                }}
            >
                <div>Redirecting...</div>
            </CallbackComponent>
        );
    }
}

CallbackPage.propTypes = {
    dispatch: PropTypes.func.isRequired
};

export default connect()(CallbackPage);
