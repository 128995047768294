// @ts-nocheck
import React, { Component } from "react";
import { FormattedMessage, IntlProvider } from 'react-intl';
import styled from 'styled-components';
import { messages } from '../constants/localization';
import PropTypes from 'prop-types';
import { connect } from "react-redux";
import { Button } from "kepler.gl/dist/components/common/styled-components"


const StyledTitle = styled.div`
  font-size: large;
  color: ${props => props.theme.labelColorLT};
  line-height: 18px;
  margin-bottom: 12px;
  font-weight: bold;
`;

const StyledDescription = styled.div`
  font-size: 14px;
  color: ${props => props.theme.labelColorLT};
  line-height: 18px;
  margin-bottom: 12px;
`;

const StyledLayerGroupItem = styled.div`
  margin-bottom: 10px;
  display: flex;
  justify-content: space-between;

  &:last-child {
    margin-bottom: 0;
  }

  .layer-group__visibility-toggle {
    margin-right: 12px;
  }
`;
class SubscriptionDialog extends Component {

  static propTypes = {
    locale: PropTypes.string.isRequired,
    oidc: PropTypes.object.isRequired,
  };




  render() {
    const { locale, oidc } = this.props;

    return (
      <IntlProvider locale={locale} messages={messages[locale]} >
        {oidc.user?.profile?.roles ?
          <div>
            <StyledTitle>
              <FormattedMessage id={'subscription.Found'} />
            </StyledTitle>
            <StyledLayerGroupItem>
              <div>
                <FormattedMessage id={'subscription.Found.Description'} />
              </div>
              <div style={{ display: 'Flex', flexDirection: 'column' }}>
                {oidc.user.profile.roles.map(r => <div>{r}</div>)}
              </div>
            </StyledLayerGroupItem>
          </div>
          :
          <div>
            <StyledTitle>
              <FormattedMessage id={'subscription.NotFound'} />
            </StyledTitle>
            <StyledDescription>
              <FormattedMessage id={'subscription.NotFound.Description'} values={{ accountName: oidc.user?.profile?.unique_name }} />
            </StyledDescription>

            <Button onClick={() => window.location.href = 'mailto:benjamin.smeding@movares.nl'}>Contact</Button>

          </div>}
      </IntlProvider>
    );
  }
}


const mapStateToProps = (state) => state;
const dispatchToProps = (dispatch) => ({ dispatch });

export default connect(mapStateToProps, dispatchToProps)(SubscriptionDialog);