import * as types from "../actions/actionTypes";
import initialState from "./initialState";

export default function lineVelocityMapInfoReducer(state = initialState.lineVelocityMapInfo, action) {
    switch (action.type) {
        case types.LOAD_LINEVELOCITY_SUCCES:
            return action.lineVelocityMapInfo;
        default:
            return state;
    }
}



