import React, { Component } from "react";
import userManager from '../utils/userManager';
import { LinkButton } from './common/styled-components';
import { media } from '../styles';
import styled from 'styled-components';
import { ThemeProvider } from 'styled-components';
import { theme } from '../styles';
import { LogoImage, Container, BgImage, Content, StyledCaption } from "./common/styles";

const GlobalStyleDiv = styled.div`
  font-family: ff-clan-web-pro, 'Helvetica Neue', Helvetica, sans-serif;
  font-weight: 400;
  font-size: 0.875em;
  line-height: 1.71429;

  *,
  *:before,
  *:after {
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
  }

  ul {
    margin: 0;
    padding: 0;
  }

  li {
    margin: 0;
  }

  a {
    text-decoration: none;
  }
`;

const ButtonContainer = styled.div`
  display: flex;
  justify-content: center;

  a {
    width: 160px;
  }

  ${media.palm`
    display: flex;
    justify-content: center;
    width: 100%;

    a {
      width: 50%;
    }
  `};
`;

export default class LoginPage extends Component {

  _loginRedirect(event) {
    event.preventDefault();
    userManager.signinRedirect();
  }
  _logoutRedirect(event) {
    event.preventDefault();
    userManager.signoutRedirect();
  }

  render() {
    return (
      <GlobalStyleDiv>
        <div>
          <ThemeProvider theme={theme}>
            <Container>
              <BgImage />
              <Content>
                <LogoImage />
                <StyledCaption>
                  <div className="kg-home__caption__subtitle">NDOV Viewer</div>
                  <div className="kg-home__caption__description">
                    <span>Login using a Microsoft account. </span>
                  </div>
                  <ButtonContainer>
                    <LinkButton large outlineDark onClick={this._loginRedirect} style={{ marginLeft: '5px' }}>
                      <img src="winlogo.png" /> Login
                      </LinkButton>
                  </ButtonContainer>
                </StyledCaption>
              </Content>
            </Container>
          </ThemeProvider>
        </div>
      </GlobalStyleDiv>
      // this.props.oidc.user ? <div> {this.props.oidc.user?.profile?.name}<button onClick={this._logoutRedirect}>Logout</button></div> :
      //     <button onClick={this._loginRedirect}>Login</button>
    );
  }
}