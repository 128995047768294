import React, { Component } from "react";
import { connect } from "react-redux";
import userManager from "../utils/userManager";
import { PanelContent, PanelLabel, Button, PanelLabelBold } from "kepler.gl/dist/components/common/styled-components"
import { FormattedMessage, IntlProvider } from 'react-intl';
import styled from 'styled-components';
import { KEPLER_GL_VERSION } from 'kepler.gl/dist/constants/default-settings';
import { Logout } from "kepler.gl/dist/components/common/icons";
import { messages } from '../constants/localization';


const StyledLayerGroupItem = styled.div`
  margin-bottom: 10px;
  display: flex;
  justify-content: space-between;

  &:last-child {
    margin-bottom: 0;
  }

  .layer-group__visibility-toggle {
    margin-right: 12px;
  }
`;
const ContentLabel = styled(PanelLabelBold)`
  color: ${props => props.theme.labelColor};
  text-transform: none;
`;
const NameLabel = styled(PanelLabelBold)`
  color: ${props => props.theme.textColor};
  text-transform: none;
`;

class InfoSidepanel extends Component {
  _loginRedirect(event) {
    event.preventDefault();
    userManager.signinRedirect();
  }
  _logoutRedirect(event) {
    event.preventDefault();
    userManager.signoutRedirect();
  }

  render() {
    const { locale } = this.props.app.keplerGl.map.uiState;


    return (
      <IntlProvider locale={locale} messages={messages[locale]} >
        <PanelLabel>
          <FormattedMessage id={'settings.AccountInfo'} />
        </PanelLabel>
        < PanelContent >
          <StyledLayerGroupItem>
            <NameLabel>
              <FormattedMessage id={'settings.Name'} />
            </NameLabel>
            <ContentLabel>
              {this.props.oidc.user?.profile?.name}
            </ContentLabel>
          </StyledLayerGroupItem>
          <StyledLayerGroupItem>
            <NameLabel>
              <FormattedMessage id={'settings.Email'} />
            </NameLabel>
            <ContentLabel>
              {this.props.oidc.user?.profile?.unique_name}
            </ContentLabel>
          </StyledLayerGroupItem>
          <StyledLayerGroupItem>
            <NameLabel>
              <FormattedMessage id={'settings.Subscriptions'} />
            </NameLabel>
            <div style={{ display: 'Flex', flexDirection: 'column' }}>
              {this.props.oidc.user?.profile?.roles ?
                this.props.oidc.user?.profile?.roles.map(r => <ContentLabel>{r}</ContentLabel>) :
                <ContentLabel>-</ContentLabel>
              }
            </div>
          </StyledLayerGroupItem>
          <StyledLayerGroupItem>
            <Button secondary onClick={this._logoutRedirect}>
              <Logout />Logout
              </Button>
          </StyledLayerGroupItem>
        </PanelContent  >

        <PanelLabel>
          <FormattedMessage id={'settings.KeplerInfo'} />
        </PanelLabel>
        < PanelContent >
          <StyledLayerGroupItem>
            <NameLabel>
              <FormattedMessage id={'settings.KeplerVersion'} />
            </NameLabel>
            <ContentLabel>
              {KEPLER_GL_VERSION}
            </ContentLabel>
          </StyledLayerGroupItem>
        </PanelContent  >
      </IntlProvider>

    );
  };
}

const mapStateToProps = (state) => state;
const dispatchToProps = (dispatch) => ({ dispatch });

export default connect(mapStateToProps, dispatchToProps)(InfoSidepanel);
