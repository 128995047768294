import * as types from "../actions/actionTypes";
import initialState from "./initialState";

export default function dwellTimesMapInfoReducer(state = initialState.dwellTimesMapInfo, action) {
    switch (action.type) {
        case types.LOAD_DWELLTIMES_SUCCES:
            return action.dwellTimesMapInfo;
        default:
            return state;
    }
}



