import * as types from "../actions/actionTypes";

const initialState = {
    isMapLoading: false
}
export default function appReducer(state = initialState, action) {
    switch (action.type) {
        case types.SET_LOADING_STATUS:
            return {
                ...state,
                isMapLoading: action.isMapLoading
            };
        default:
            return state;
    }
}