import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { KEPLER_GL_NAME, KEPLER_GL_VERSION, KEPLER_GL_WEBSITE } from 'kepler.gl/dist/constants/default-settings';



const LogoWrapper = styled.div`
  display: flex;
  align-items: flex-start;
`;

const LogoSvgWrapper = styled.div`
  margin-top: 3px;
`;



const KeplerGlLogo = () => (
    <LogoWrapper className="side-panel-logo">
        <LogoSvgWrapper>
            <img src="logo.png" height="25px" alt="Movares"></img>
        </LogoSvgWrapper>
    </LogoWrapper>
);

KeplerGlLogo.propTypes = {
    appName: PropTypes.string,
    version: PropTypes.string,
    appWebsite: PropTypes.string
};

KeplerGlLogo.defaultProps = {
    appName: KEPLER_GL_NAME,
    version: KEPLER_GL_VERSION,
    appWebsite: KEPLER_GL_WEBSITE
};

export default KeplerGlLogo;