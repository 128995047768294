import React, { Component } from "react";
import styled from "styled-components";
import { LoadingDialog } from "kepler.gl/components";
import Grid from "kepler.gl/dist/components/common/data-table/grid";
import { AutoSizer } from "react-virtualized";
import { Button, Icons } from "kepler.gl/components";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as storageActions from "../actions/storageActions";
import PropTypes from "prop-types";

const defaultRowHeight = 32;
const defaultColumnCount = 3;

const StyledError = styled.div`
  color: red;
  font-size: 14px;
  margin-bottom: 16px;
`;

const Container = styled.div`
  display: flex;
  width: 100%;
  flex-grow: 1;

  .NoScroll {
    max-width: 500px;
    overflow-y:hidden;
  }
  
  .ReactVirtualized__Grid {
    outline: none;
  }
    .result-link {
      text-decoration: none;
    }
  }
  .cell.align-right {
    align-items: flex-end;
  }
  .more {
    line-height: 10px;
    width: 100px;    
  }
`;

const renderDataCell = (mapState, onAddRemoteMap, mapInfo) => {
  return (cellInfo) => {
    const { columnIndex, key, style, rowIndex } = cellInfo;
    const isLastColumn = columnIndex === defaultColumnCount - 1;
    const hasButton =
      isLastColumn && mapInfo[rowIndex][columnIndex] !== undefined;
    const content = mapInfo[rowIndex][columnIndex];

    const cell = (
      <div
        key={key}
        style={style}
      >
        {hasButton ? (
          <Button
            className="more"
            onClick={() => onAddRemoteMap(mapState, content)}
          >
            <Icons.Add height="12px" />
            Add Map
          </Button>
        ) : (
            content
          )}
      </div>
    );
    return cell;
  };
};
const TableSection = ({
  mapState,
  mapInfo,
  onAddRemoteMap,
  dataGridProps,
}) => (
    <AutoSizer >
      {({ width, height }) => {
        const gridDimension = {
          columnCount: defaultColumnCount,
          width: width,
        };

        const columnWidth = 120;
        const firstColumnWidth = width - ((defaultColumnCount - 1) * columnWidth)

        return (
          <Grid
            autoContainerWidth={true}
            cellRenderer={renderDataCell(mapState, onAddRemoteMap, mapInfo)}
            height={height}
            columnWidth={o => {
              return o.index === 0 ? firstColumnWidth : columnWidth
            }}
            {...dataGridProps}
            {...gridDimension}
          />
        );
      }}
    </AutoSizer>
  );


export class DwellTimesTab extends Component {
  componentDidMount() {
    const { dwellTimesMapInfo, actions } = this.props;

    if (dwellTimesMapInfo.length === 0) {
      actions.loadDwellTimesMaps().catch((error) => {
        alert(error);
      });
    }
  }

  render() {
    const {
      mapState,
      error,
      isMapLoading,
      onAddRemoteMap,
      dwellTimesMapInfo,
    } = this.props;

    const dataGridProps = {
      cellSizeCache: {},
      className: "body-grid",
      rowCount: dwellTimesMapInfo.length,
      rowHeight: defaultRowHeight,
    };

    return (
      <Container>
        {error ? (
          <StyledError>{error.message}</StyledError>
        ) : isMapLoading || dwellTimesMapInfo.length === 0 ? (
          <LoadingDialog size={64} message="Loading..." />
        ) : (
              <TableSection
                mapState={mapState}
                mapInfo={dwellTimesMapInfo}
                dataGridProps={dataGridProps}
                onAddRemoteMap={onAddRemoteMap}
              />
            )}
      </Container>
    );
  }
}

DwellTimesTab.propTypes = {
  state: PropTypes.object.isRequired,
  actions: PropTypes.object.isRequired,
  dwellTimesMapInfo: PropTypes.array.isRequired,
  isMapLoading: PropTypes.bool.isRequired,
};

// const mapStateToProps = state => state;
function mapStateToProps(state) {
  return {
    dwellTimesMapInfo:
      state.app.dwellTimesMapInfo.length === 0
        ? []
        : state.app.dwellTimesMapInfo.map((info) => {
          return { ...info };
        }),
    isMapLoading: state.app.appReducer.isMapLoading,
    mapState: state.app.keplerGl.map, //volgens mij is dit verboden in redux
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: {
      loadDwellTimesMaps: bindActionCreators(
        storageActions.loadDwellTimesMaps,
        dispatch
      ),
    },
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(DwellTimesTab);
