import { LoadDataModalFactory } from "kepler.gl/components";
import { withState } from "kepler.gl/components";
import { LOADING_METHODS } from "../constants/default-settings";
import { addRemoteMap } from "../actions/appActions";
import DwellTimesTab from "../components/DwellTimesTab";
import travelVelocityTab from "../components/TravelVelocityTab";

const additionalMethods = {
  dwellTimesTab: {
    id: LOADING_METHODS.dwellTimes,
    label: "Dwelltimes",
    elementType: DwellTimesTab,
  },
  travelVelocityTab: {
    id: LOADING_METHODS.lineVelocity,
    label: "Travel velocity",
    elementType: travelVelocityTab,
  },
};

const CustomLoadDataModalFactory = (...deps) => {
  const LoadDataModal = LoadDataModalFactory(...deps);
  const defaultLoadingMethods = LoadDataModal.defaultProps.loadingMethods;

  // add more loading methods
  LoadDataModal.defaultProps = {
    ...LoadDataModal.defaultProps,
    loadingMethods: [
      additionalMethods.dwellTimesTab,
      additionalMethods.travelVelocityTab,
      defaultLoadingMethods.find((lm) => lm.id === "upload"),
    ],
  };

  return withState([], (state) => ({ ...state.app }), {
    onAddRemoteMap: addRemoteMap,
  })(LoadDataModal);
};

CustomLoadDataModalFactory.deps = LoadDataModalFactory.deps;

export function replaceLoadDataModalFactory() {
  return [LoadDataModalFactory, CustomLoadDataModalFactory];
}
