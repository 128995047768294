import React from "react";
import { render } from "react-dom";
import { Provider } from "react-redux";
import { ConnectedRouter } from 'connected-react-router';
import configureStore from './configureStore';
import routes from "./router";
import { createBrowserHistory } from "history";

const history = createBrowserHistory();
const initialState = window.initialReduxState;

// @ts-ignore
const store = configureStore(history, initialState);

const Root = () => (
  <Provider store={store}>
    <ConnectedRouter history={history}>
      {routes}
    </ConnectedRouter>
  </Provider>
);

render(<Root />, document.getElementById("root"));
