import { handleResponse, handleError } from "./apiUtils";
import { AZURE_STORAGE_ACCOUNT } from "../constants/default-settings";
import xml2js from "react-native-xml2js";

export function getContainerInfo(containerName) {
  return fetch(
    AZURE_STORAGE_ACCOUNT.BASE_URL +
    containerName +
    "?restype=container&comp=list"
  )
    .then(handleResponse)
    .catch(handleError)
    .then((xmlResponse) => {
      let result;
      xml2js.parseString(xmlResponse, (error, response) => {
        result = response.EnumerationResults.Blobs[0]
          ? response.EnumerationResults.Blobs[0].Blob.map((b) => [
            removeFileExtension(b.Name[0]),
            formatBytes(b.Properties[0]["Content-Length"][0]),
            b.Url[0],
          ]).reverse()
          : [];
      });

      return result;
    });
}

function formatBytes(bytes, decimals = 2) {
  if (bytes === 0) return "0 Bytes";

  const k = 1024;
  const dm = decimals < 0 ? 0 : decimals;
  const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];

  const i = Math.floor(Math.log(bytes) / Math.log(k));

  return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i];
}

function removeFileExtension(fileName) {
  return fileName.split('.').slice(0, -1).join('.')
}
