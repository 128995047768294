import { Save } from 'kepler.gl/dist/components/common/icons';
import { PanelHeaderFactory, SaveExportDropdownFactory, PanelHeaderDropdownFactory } from 'kepler.gl/components';
import KeplerGlLogo from './panel-logo';


// Custom Panel Header renders default panel header, changing its default props
// to avoid rendering any action items on the top right
export function CustomPanelHeaderFactory() {
    const PanelHeader = PanelHeaderFactory();

    const PanelHeaderDropdown = PanelHeaderDropdownFactory();
    const SaveExportDropdown = SaveExportDropdownFactory(PanelHeaderDropdown);

    PanelHeader.defaultProps = {
        // ...PanelHeader.defaultProps,
        logoComponent: KeplerGlLogo,
        actionItems: [
            {
                id: 'export',
                iconComponent: Save,
                onClick: () => { },
                label: 'Export',
                dropdownComponent: SaveExportDropdown
            }
        ]
    };

    return PanelHeader;
}



export function replacePanelHeaderFactory() {
    return [PanelHeaderFactory, CustomPanelHeaderFactory];
}