import * as types from "./actionTypes";
import { beginApiCall, apiCallError } from "./apiStatusActions";
import { getContainerInfo } from "../api/storageApi";
import { AZURE_STORAGE_ACCOUNT } from "../constants/default-settings";

export function loadDwellTimesMapInfoSucces(dwellTimesMapInfo) {
    return { type: types.LOAD_DWELLTIMES_SUCCES, dwellTimesMapInfo };
}
export function loadLineVelocityMapInfoSucces(lineVelocityMapInfo) {
    return { type: types.LOAD_LINEVELOCITY_SUCCES, lineVelocityMapInfo };
}

export function loadDwellTimesMaps() {
    return function (dispatch) {
        dispatch(beginApiCall());
        return getContainerInfo(AZURE_STORAGE_ACCOUNT.DWELLTIMES_CONTAINER)
            .then(dwellTimesMapInfo => {
                dispatch(loadDwellTimesMapInfoSucces(dwellTimesMapInfo));
            }).catch(error => {
                dispatch(apiCallError());
                throw error;
            })
    }
}

export function loadLineVelocityMaps() {
    return function (dispatch) {
        dispatch(beginApiCall());
        return getContainerInfo(AZURE_STORAGE_ACCOUNT.TRAVELVELOCITY_CONTAINER)
            .then(lineVelocityMapInfo => {
                dispatch(loadLineVelocityMapInfoSucces(lineVelocityMapInfo));
            }).catch(error => {
                dispatch(apiCallError());
                throw error;
            })
    }
}