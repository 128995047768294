import keyMirror from "keymirror";

export const LOADING_METHODS = keyMirror({
  dwellTimes: null,
  lineVelocity: null,
});

export const DEFAULT_FEATURE_FLAGS = {
  cloudStorage: false,
};

export const AUTH_TOKENS = {
  MAPBOX_TOKEN: process.env.REACT_APP_MapboxAccessToken, // eslint-disable-line
  EXPORT_MAPBOX_TOKEN: process.env.REACT_APP_MapboxAccessToken, // eslint-disable-line
};

export const AZURE_STORAGE_ACCOUNT = {
  BASE_URL: "https://ndovstorage.blob.core.windows.net/",
  DWELLTIMES_CONTAINER: "ndov-dwelltimes",
  TRAVELVELOCITY_CONTAINER: "ndov-travelvelocity",
};

export const SHOW_SUBSCRIPTION_ID = 'showSubscribtion';
