import React, { Component } from "react";
import { connect } from "react-redux";
import window from "global/window";
import { AUTH_TOKENS, SHOW_SUBSCRIPTION_ID } from "../constants/default-settings";
import AutoSizer from "react-virtualized/dist/commonjs/AutoSizer";
import { injectComponents } from "kepler.gl/components";
import { replaceLoadDataModalFactory } from "../factories/load-data-modal";
import { replacePanelHeaderFactory } from "../factories/panel-header";
import { replaceModalContainerFactory } from "../factories/custom-modal-container";
import { addSidePanelTabs } from "../factories/custom-panel";
import { toggleModal } from "kepler.gl/actions"


const keplerGlGetState = (state) => state.app.keplerGl;

// Inject custom components
const KeplerGl = injectComponents([
  replaceModalContainerFactory(),
  replacePanelHeaderFactory(),
  replaceLoadDataModalFactory(),
  addSidePanelTabs(),
]);


class MainPage extends Component {
  state = {
    showBanner: false,
    width: window.innerWidth,
    height: window.innerHeight,
  };

  componentDidMount() {
    if (!this.props.oidc.user?.profile?.roles) {
      this.props.dispatch(toggleModal(SHOW_SUBSCRIPTION_ID))
    }
  }


  render() {
    return (
      <div
        style={{
          position: "absolute",
          width: "100%",
          height: "100%",
        }}
      >

        <AutoSizer>
          {({ height, width }) => (
            <KeplerGl
              id="map"
              getState={keplerGlGetState}
              mapboxApiAccessToken={AUTH_TOKENS.MAPBOX_TOKEN}
              width={width}
              height={height}
            />
          )}
        </AutoSizer>
      </div>
    );
  }
}

const mapStateToProps = (state) => state;
const dispatchToProps = (dispatch) => ({ dispatch });

export default connect(mapStateToProps, dispatchToProps)(MainPage);
