// CONSTANTS
export const INIT = "INIT";
export const LOAD_REMOTE_RESOURCE_SUCCESS = "LOAD_REMOTE_RESOURCE_SUCCESS";
export const LOAD_REMOTE_RESOURCE_ERROR = "LOAD_REMOTE_RESOURCE_ERROR";
export const SET_LOADING_STATUS = "SET_LOADING_STATUS";

export const BEGIN_API_CALL = "BEGIN_API_CALL";
export const API_CALL_ERROR = "API_CALL_ERROR";
export const LOAD_DWELLTIMES_SUCCES = "LOAD_DWELLTIMES_SUCCES";
export const LOAD_LINEVELOCITY_SUCCES = "LOAD_LINEVELOCITY_SUCCES";
