import React from 'react';
import { Route, Switch } from 'react-router';
import CallbackPage from "./components/CallbackPage";
import MainPage from './components/MainPage';
import PrivateRoute from './components/common/PrivateRoute';

const routes = (
    <Switch>
        <PrivateRoute exact path="/" component={MainPage} />
        <Route path="/callback" component={CallbackPage} />
    </Switch>
);

export default routes;