import { parseUri } from "../utils/url";
import { request } from "d3-request";
// @ts-ignore
import { loadFiles, addDataToMap } from "kepler.gl/actions";
import { LOAD_REMOTE_RESOURCE_ERROR, SET_LOADING_STATUS } from "./actionTypes";
import KeplerGlSchema from "kepler.gl/schemas";

export function loadRemoteResourceError(error, url) {
  return { type: LOAD_REMOTE_RESOURCE_ERROR, error, url };
}

export function setLoadingMapStatus(isMapLoading) {
  return { type: SET_LOADING_STATUS, isMapLoading };
}

export function loadRemoteMap(dataUrl) {
  return (dispatch) => {
    dispatch(setLoadingMapStatus(true));
    // breakdown url into url+query params
    loadRemoteRawData(dataUrl).then(
      // In this part we turn the response into a FileBlob
      // so we can use it to call loadFiles
      ([file, url]) => {
        const { file: filename } = parseUri(url);
        dispatch(loadFiles([new File([file], filename)]));
        dispatch(setLoadingMapStatus(false));
      },
      (error) => {
        const { target = {} } = error;
        const { status, responseText } = target;
        dispatch(
          loadRemoteResourceError({ status, message: responseText }, dataUrl)
        );
      }
    );
  };
}
export function addRemoteMap(mapState, dataUrl) {
  return (dispatch) => {
    dispatch(setLoadingMapStatus(true));
    loadRemoteRawData(dataUrl).then(
      ([file]) => {
        var newData = JSON.parse(file);
        const oldData = KeplerGlSchema.save(mapState);
        const mapToLoad = mergeMapData(oldData, newData);

        dispatch(addDataToMap(mapToLoad));
        dispatch(setLoadingMapStatus(false));
      },
      (error) => {
        const { target = {} } = error;
        const { status, responseText } = target;
        dispatch(
          loadRemoteResourceError({ status, message: responseText }, dataUrl)
        );
      }
    );
  };
}

function mergeMapData(oldData, newData) {

  const newDataSets = newData.datasets;
  const newConfig = newData.config;
  const dataSets = oldData.datasets;
  const config = oldData.config;


  newDataSets.forEach(dataset => {
    const oldDataSet = dataSets.find(d => d.data.label === dataset.data.label)
    if (oldDataSet) {
      oldDataSet.data.allData = [...oldDataSet.data.allData, ...dataset.data.allData]
    }
    else {
      dataSets.push(dataset);
      const missingLayer = getLayerByDataId(newConfig.config.visState.layers, dataset.data.id);
      config.config.visState.layers.push(missingLayer);
      const missingTooltip = getTooltipByDataId(newConfig.config.visState.interactionConfig.tooltip, dataset.data.id);
      if (missingTooltip) {
        config.config.visState.interactionConfig.tooltip.fieldsToShow[dataset.data.id] = missingTooltip;
      }
    }
  });


  const mapToLoad = KeplerGlSchema.load(dataSets, config);
  return mapToLoad;
}

function getLayerByDataId(layers, dataId) {
  return layers.find(l => l.config.dataId === dataId);
}

function getTooltipByDataId(tooltip, dataId) {
  return tooltip.fieldsToShow[dataId];
}


function loadRemoteRawData(url) {
  if (!url) {
    // TODO: we should return reject with an appropriate error
    return Promise.resolve(null);
  }

  return new Promise((resolve, reject) => {
    request(url, (error, result) => {
      if (error) {
        reject(error);
        return;
      }
      const responseError = detectResponseError(result);
      if (responseError) {
        reject(responseError);
        return;
      }
      resolve([result.response, url]);
    });
  });
}

function detectResponseError(response) {
  if (
    response.statusCode &&
    (response.statusCode < 200 || response.statusCode >= 300)
  ) {
    return {
      status: response.statusCode,
      message: response.body || response.message || response,
    };
  }
}
