import { combineReducers } from "redux";
import keplerGlReducer, { uiStateUpdaters } from "kepler.gl/reducers";
import apiCallsInProgress from "./apiStatusReducer";
import dwellTimesMapInfo from "./dwellTimesMapInfoReducer";
import lineVelocityMapInfo from "./lineVelocityMapInfoReducer";
import appReducer from "./appReducer";
import { EXPORT_MAP_FORMATS, EXPORT_IMAGE_ID } from "kepler.gl/constants";
import { AUTH_TOKENS } from "../constants/default-settings";
import { LOCALE_CODES } from 'kepler.gl/localization';

const { DEFAULT_EXPORT_MAP } = uiStateUpdaters;

const rootReducer = combineReducers({
  keplerGl: keplerGlReducer.initialState({
    mapState: {
      bearing: 0,
      dragRotate: false,
      latitude: 51.92,
      longitude: 4.45,
      pitch: 0,
      zoom: 12,
      isSplit: false,
    },
    uiState: {
      locale: LOCALE_CODES.en,
      currentModal: null,
      mapControls: {
        splitMap: { show: true },
        visibleLayers: { show: true },
        toggle3d: { show: true },
        mapLegend: { show: true },
        mapDraw: { show: false },
      },
      exportMap: {
        ...DEFAULT_EXPORT_MAP,
        [EXPORT_MAP_FORMATS.HTML]: {
          ...DEFAULT_EXPORT_MAP[[EXPORT_MAP_FORMATS.HTML]],
          exportMapboxAccessToken: AUTH_TOKENS.EXPORT_MAPBOX_TOKEN,
        },
      },
    },
  }),
  appReducer,
  dwellTimesMapInfo,
  lineVelocityMapInfo,
  apiCallsInProgress,
});

const composedReducer = (state, action) => {
  return rootReducer(state, action);
};

export default composedReducer;
