import React from 'react';
import { Icons, CustomPanelsFactory } from 'kepler.gl/components';
import InfoSidepanel from '../components/SettingSidepanel';

function CustomSidePanelsFactory() {
  const CustomPanels = props => {
    if (props.activeSidePanel === 'info') {
      return <InfoSidepanel />;
    }

    return null;
  };

  CustomPanels.defaultProps = {
    panels: [
      {
        id: 'info',
        label: 'Settings',
        iconComponent: Icons.Gear
      }
    ],
    getProps: props => ({
      layers: props.layers
    })
  };

  return CustomPanels;
}
export function addSidePanelTabs() {
  return [CustomPanelsFactory, CustomSidePanelsFactory];
}
